const getName = (data) => {
    let nameProp = 'Name';
    if (data && !data[nameProp]) {
        let foundProp = Object.keys(data).find(x => x == 'Name');
        // if (!foundProp) foundProp = Object.keys(data).find(x => x.includes('Description'));
        if (foundProp) nameProp = foundProp;
    }
    return nameProp;
}
const getArray = (data, propPath) => {
    let value = data[propPath];
    if (isObject(value)) {
        return Object.keys(value).map(x => {
            value[x].Id = x;
            return value[x];
        });
    }
    else {
        return value;
    }
}
const getCookieValue = (name) => {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
}
const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const isObject = (value) => typeof value == 'object' && value !== null && !(value instanceof Array) && !(value instanceof Date);

const createEmptyCopy = (original) => {
    let template = { ...original }
    if (isObject(template)) {
        Object.keys(template).forEach(key => {
            let value = 'placeholder';
            switch (typeof template[key]) {
                case "string":
                    template[key] = value;
                    break;
                case "boolean":
                    template[key] = false;
                    break;
                case "bigint":
                case "number":
                    template[key] = 0;
                    break;
            }
        });
    }
    return template;
}
const createCopyFromTemplate = (template) => {
    if (isObject(template) && !template.IsEnum) {
        let obj = {};
        let keys = Object.keys(template);
        let isDictionary = false;
        if (keys.length == 2 && template.KeyType != undefined && template.ValueType != undefined) {
            isDictionary = true;
        }
        if (!isDictionary) {
            keys.forEach(key => {
                obj[key] = createCopyFromTemplate(template[key]);
            });
        }
        return obj;
    } else if (Array.isArray(template)) {
        return [];
    }

    if (template.IsEnum)
        return template.Values[0];

    if (template.includes("?"))
        return null;

    switch (template) {
        case "String":
            return "";
        case "DateTime":
        case "DateTimeOffset":
            return new Date();
        case "Int32":
        case "Int16":
        case "Int64":
        case "Single":
        case "Double":
            return 0;
        case "Boolean":
            return false;
    }

    return null;
}

export default {
    getName: getName,
    getArray: getArray,
    isObject: isObject,
    createEmptyCopy: createEmptyCopy,
    createCopyFromTemplate: createCopyFromTemplate,
    getCookieValue: getCookieValue,
    setCookie: setCookie
}