import { useCallback, useState } from "react";
import utils from "../../utils";

function GenericEditor({ data, template, escapeProps = []
    , setParentState = (data) => { }, hideSave = false
    , onSave = (state) => { }, hasChangesRef = { current: false }, hidden = false }) {
    let [state, setState] = useState(data);

    let modifyState = useCallback((newState) => {
        if (!hasChangesRef.current) hasChangesRef.current = true;
        setState({ ...newState });
        setParentState({ ...newState });
    }, []);

    let nameProp = utils.getName(state);

    let inputProps = [];
    let arrayProps = [];
    let dictionaryProps = [];
    let objectProps = [];
    Object.keys(template.properties).forEach(prop => {
        if (escapeProps.includes(prop)) return;

        if (state[prop] instanceof Array) arrayProps.push(prop);
        else if (utils.isObject(template.properties[prop]) && template.properties[prop].KeyType && !template.properties[prop].IsEnum) dictionaryProps.push(prop);
        else if (utils.isObject(template.properties[prop]) && !template.properties[prop].KeyType && !template.properties[prop].IsEnum) objectProps.push(prop);
        else if (prop.toLocaleLowerCase() != 'id'
            && prop.toLocaleLowerCase() != nameProp.toLocaleLowerCase())
            return inputProps.push(prop);
    })
    return (
        <div hidden={hidden} className="p-4 rounded-lg bg-gray-50 ">
            {state.Id != undefined ?
                <div className='mb-6'>
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Id</label>
                    <input type="number" value={state.Id} id="name" disabled={true} className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="1" required />
                </div>
                : ""}
            {state.Name != null ?
                <div className='mb-6'>
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                    <input type="text" value={state[nameProp]} onChange={txt => { state[nameProp] = txt.target.value; modifyState(state); }} id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Dungeon 1" required />
                </div>
                : ""}
            <div>
                <div className="grid gap-6 mb-6 md:grid-cols-4">
                    {inputProps.map(prop => {
                        if (utils.isObject(template.properties[prop]) && template.properties[prop].IsEnum) {
                            return (<div key={'property-' + prop}>
                                <label htmlFor={prop} className="block mb-2 text-sm font-medium text-gray-900">{prop.split(/(?=[A-Z])/).join(" ")}</label>
                                <select name={prop} id={prop} onChange={ev => {
                                    state[prop] = parseInt(ev.target.value);
                                    modifyState(state);
                                }}
                                    value={state[prop]} className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                    required >
                                    {
                                        template.properties[prop].EnumNames.map((enumName, i) => {
                                            console.log(enumName);
                                            return <option key={`property-${prop}-${enumName}`} value={template.properties[prop].Values[i]}>{enumName}</option>
                                        })
                                    }
                                </select>
                            </div>);
                        }
                        let inputType = "text";
                        switch (template.properties[prop]) {
                            case "String":
                            case "String?":
                            case "DateTime":
                            case "DateTime?":
                            case "DateTimeOffset":
                            case "DateTimeOffset?":
                                inputType = "text";
                                break;
                            case "Int32":
                            case "Int32?":
                            case "Int16":
                            case "Int16?":
                            case "Int64":
                            case "Int64?":
                            case "Single":
                            case "Single?":
                            case "Double":
                            case "Double?":
                                inputType = "number";
                                break;
                            case "Boolean":
                            case "Boolean?":
                                inputType = "checkbox";
                                break;
                        }
                        return (<div key={'property-' + prop}>
                            <label htmlFor={prop} className="block mb-2 text-sm font-medium text-gray-900">{prop.split(/(?=[A-Z])/).join(" ")}</label>
                            <input type={inputType} name={prop} id={prop} onChange={txt => {

                                switch (template.properties[prop]) {

                                    case "String":
                                    case "String?":
                                    case "DateTime":
                                    case "DateTime?":
                                    case "DateTimeOffset":
                                    case "DateTimeOffset?":
                                        state[prop] = txt.target.value;
                                        break;
                                    case "Int32":
                                    case "Int32?":
                                    case "Int16":
                                    case "Int16?":
                                    case "Int64":
                                    case "Int64?":
                                    case "Single":
                                    case "Single?":
                                    case "Double":
                                    case "Double?":
                                        state[prop] = txt.target.valueAsNumber;
                                        break;
                                    case "Boolean":
                                    case "Boolean?":
                                        state[prop] = txt.target.checked;
                                        break;
                                }
                                modifyState(state);
                            }} checked={(typeof state[prop] == "boolean" ? state[prop] : false)} value={state[prop]} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Placeholder" required />
                        </div>);
                    })}
                </div>
                <div className="w-full flex flex-wrap flex-row mb-2">
                    {objectProps.map(prop => {
                        if (state[prop] == null) {
                            return (
                                <div>
                                    <div key={'property-' + prop} className="w-full mb-4">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{prop.split(/(?=[A-Z])/).join(" ")}</h5>
                                        This Object property is Null
                                    </div>
                                    <button type="button" className="text-white p-2 ms-3 rounded-lg bg-green-600 hover:bg-green-500"
                                        onClick={() => {
                                            let entity = utils.createCopyFromTemplate(template.properties[prop]);
                                            if (entity != null) {
                                                state[prop] = entity;
                                                modifyState(state);
                                            }
                                        }}>
                                        Create
                                    </button>
                                </div>
                            )
                        }
                        return (
                            <div key={'property-' + prop} className="w-full mb-4">
                                <h5 className="flex mb-2 text-2xl font-bold tracking-tight text-gray-900">{prop.split(/(?=[A-Z])/).join(" ")}
                                    <button type="button" className="flex justify-center items-center w-12 h-15 mt-1 text-center ms-3 rounded-lg bg-red-500 hover:bg-red-400"
                                        onClick={() => {
                                            if (confirm("Are you sure you want to delete this item?")) {
                                                state[prop] = null;
                                                modifyState(state);
                                            }
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-4 block">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>
                                    </button>
                                </h5>
                                <div className="flex flex-wrap flex-column justify-content">
                                    <div className="flex w-full">
                                        <GenericEditor
                                            key={'property-' + prop}
                                            data={state[prop]}
                                            template={{ properties: template.properties[prop] }}
                                            hideSave={true}
                                            setParentState={(updatedProp) => {
                                                state[prop] = updatedProp;
                                                modifyState(state);
                                            }}
                                        >

                                        </GenericEditor>

                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <div className="w-full flex flex-wrap flex-row justify-center">
                    {arrayProps.map(prop => {
                        return (
                            <div key={'property-' + prop} className="w-full mb-4">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{prop.split(/(?=[A-Z])/).join(" ")}
                                    <button type="button" className="w-5 ms-3 rounded-full bg-green-600 hover:bg-green-500"
                                        onClick={() => {
                                            let entity = utils.createCopyFromTemplate(template.properties[prop][0]);
                                            if (entity != null) {
                                                state[prop].push(entity);
                                                modifyState(state);
                                            }
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" aria-hidden="true" className="nz sb"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path></svg>
                                    </button>
                                </h5>
                                <div className="flex flex-wrap flex-column justify-content">
                                    {
                                        Object.values(state[prop]).map((val, i) => {
                                            let inputType = "text";
                                            let isObject = utils.isObject(template.properties[prop][0]);
                                            if (isObject) {
                                                return (
                                                    <div className="flex w-full">
                                                        <GenericEditor
                                                            key={'property-' + prop + '-' + i}
                                                            data={val}
                                                            template={{ properties: template.properties[prop][0] }}
                                                            hideSave={true}
                                                            setParentState={(updatedProp) => {
                                                                state[prop][i] = updatedProp;
                                                                modifyState(state);
                                                            }}
                                                        >

                                                        </GenericEditor>
                                                        <button type="button" className="flex self-center justify-center items-center max-w-3 w-1/6 h-1/6 mt-1 text-center ms-3 rounded-lg bg-red-500 hover:bg-red-400"
                                                            onClick={() => {
                                                                if (confirm("Are you sure you want to delete this item?")) {
                                                                    state[prop].splice(i, 1);
                                                                    modifyState(state);
                                                                }
                                                            }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-4 block">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )
                                            }
                                            switch (template.properties[prop][0]) {
                                                case "String":
                                                case "String?":
                                                case "DateTime":
                                                case "DateTime?":
                                                case "DateTimeOffset":
                                                case "DateTimeOffset?":
                                                    inputType = "text";
                                                    break;
                                                case "Int32":
                                                case "Int32?":
                                                case "Int16":
                                                case "Int16?":
                                                case "Int64":
                                                case "Int64?":
                                                case "Single":
                                                case "Single?":
                                                case "Double":
                                                case "Double?":
                                                    inputType = "number";
                                                    break;
                                                case "Boolean":
                                                case "Boolean?":
                                                    inputType = "checkbox";
                                                    break;
                                            }
                                            return (
                                                <div className="me-2 flex flex-row min-w-10 max-w-10 " key={'property-' + prop + '-' + i}>
                                                    <input type={inputType} name={prop} id={prop} onChange={txt => {

                                                        switch (template.properties[prop][0]) {

                                                            case "String":
                                                            case "String?":
                                                            case "DateTime":
                                                            case "DateTime?":
                                                            case "DateTimeOffset":
                                                            case "DateTimeOffset?":
                                                                state[prop][i] = txt.target.value;
                                                                break;
                                                            case "Int32":
                                                            case "Int32?":
                                                            case "Int16":
                                                            case "Int16?":
                                                            case "Int64":
                                                            case "Int64?":
                                                            case "Single":
                                                            case "Single?":
                                                            case "Double":
                                                            case "Double?":
                                                                state[prop][i] = txt.target.valueAsNumber;
                                                                break;
                                                            case "Boolean":
                                                            case "Boolean?":
                                                                state[prop][i] = txt.target.checked;
                                                                break;
                                                        }
                                                        modifyState(state);
                                                    }} value={state[prop][i]} className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Placeholder" required />
                                                    <button type="button" className="flex justify-center items-center w-1/4 h-3/4 mt-1 text-center ms-3 rounded-lg bg-red-500 hover:bg-red-400"
                                                        onClick={() => {
                                                            if (confirm("Are you sure you want to delete this item?")) {
                                                                state[prop].splice(i, 1);
                                                                modifyState(state);
                                                            }
                                                        }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-4 block">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                        </svg>
                                                    </button>
                                                </div>);
                                        })
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="w-full flex flex-wrap flex-row justify-center">
                    {dictionaryProps.map(prop => {
                        return (
                            <div key={'property-' + prop} className="w-full mb-4">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{prop.split(/(?=[A-Z])/).join(" ")}
                                    <button type="button" className="w-5 ms-3 rounded-full bg-green-600 hover:bg-green-500"
                                        onClick={() => {
                                            let entity = utils.createCopyFromTemplate(template.properties[prop].ValueType);
                                            if (entity != null) {
                                                let key = null;
                                                switch (template.properties[prop].KeyType) {
                                                    case "String?":
                                                    case "String":
                                                        key = "RandomKey_" + Object.keys(state[prop]).length;
                                                        break;

                                                    case "Int32":
                                                    case "Int32?":
                                                    case "Int16":
                                                    case "Int16?":
                                                    case "Int64":
                                                    case "Int64?":
                                                    case "Single":
                                                    case "Single?":
                                                    case "Double":
                                                    case "Double?":
                                                        let ids = Object.keys(state[prop]).map(x => parseFloat(x));
                                                        key = ids.length == 0 ? 1 : Math.max(...ids) + 1;
                                                        break;
                                                }
                                                state[prop][key] = entity;
                                                modifyState(state);
                                            }
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" aria-hidden="true" className="nz sb"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path></svg>
                                    </button>
                                </h5>
                                <div className="flex flex-wrap flex-col justify-content">
                                    {
                                        Object.keys(state[prop]).map((key) => {
                                            let keyType = "text";
                                            let inputType = "text";
                                            switch (template.properties[prop].KeyType) {
                                                case "String":
                                                    keyType = "text"
                                                    break;

                                                case "Int32":
                                                case "Int16":
                                                case "Int64":
                                                case "Single":
                                                case "Double":
                                                    keyType = "number";
                                            }
                                            let isObject = utils.isObject(template.properties[prop].ValueType);
                                            if (isObject) {
                                                return (
                                                    <div className="flex w-full mb-2">
                                                        <div className="p-5 rounded-lg bg-gray-50 ">
                                                            Key:
                                                            <input type={keyType} name={"key-" + prop} id={"key-" + prop} onChange={txt => {
                                                                let keyValue = null;
                                                                switch (template.properties[prop].KeyType) {
                                                                    case "String":
                                                                        keyValue = txt.target.value;
                                                                        break;
                                                                    case "Int32":
                                                                    case "Int32?":
                                                                    case "Int16":
                                                                    case "Int16?":
                                                                    case "Int64":
                                                                    case "Int64?":
                                                                    case "Single":
                                                                    case "Single?":
                                                                    case "Double":
                                                                    case "Double?":
                                                                        keyValue = txt.target.valueAsNumber;
                                                                        break;
                                                                }
                                                                if (state[prop][keyValue] == undefined) {
                                                                    state[prop][keyValue] = state[prop][key];
                                                                    delete state[prop][key];
                                                                    modifyState(state);
                                                                }
                                                            }}
                                                                value={key} className="me-2 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                                placeholder="Key" required />
                                                        </div>
                                                        <GenericEditor
                                                            key={'property-' + prop + '-' + key}
                                                            data={state[prop][key]}
                                                            template={{ properties: template.properties[prop].ValueType }}
                                                            hideSave={true}
                                                            setParentState={(updatedProp) => {
                                                                state[prop][key] = updatedProp;
                                                                modifyState(state);
                                                            }}
                                                        />
                                                        <button type="button" className="flex self-center justify-center items-center max-w-3 w-1/6 h-1/6 mt-1 text-center ms-3 rounded-lg bg-red-500 hover:bg-red-400"
                                                            onClick={() => {
                                                                if (confirm("Are you sure you want to delete this item?")) {
                                                                    delete state[prop][key];
                                                                    modifyState(state);
                                                                }
                                                            }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-4 block">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )
                                            }
                                            switch (template.properties[prop].ValueType) {
                                                case "String":
                                                case "String?":
                                                case "DateTime":
                                                case "DateTime?":
                                                case "DateTimeOffset":
                                                case "DateTimeOffset?":
                                                    inputType = "text";
                                                    break;
                                                case "Int32":
                                                case "Int32?":
                                                case "Int16":
                                                case "Int16?":
                                                case "Int64":
                                                case "Int64?":
                                                case "Single":
                                                case "Single?":
                                                case "Double":
                                                case "Double?":
                                                    inputType = "number";
                                                    break;
                                                case "Boolean":
                                                case "Boolean?":
                                                    inputType = "checkbox";
                                                    break;
                                            }
                                            return (
                                                <div className="mb-2 flex flex-row min-w-10 max-w-30 " key={'property-' + prop + '-' + key}>
                                                    <input type={keyType} name={"key-" + prop} id={"key-" + prop} onChange={txt => {
                                                        let keyValue = null;
                                                        switch (template.properties[prop].KeyType) {
                                                            case "String":
                                                                keyValue = txt.target.value;
                                                                break;
                                                            case "Int32":
                                                            case "Int16":
                                                            case "Int64":
                                                            case "Single":
                                                            case "Double":
                                                                keyValue = txt.target.valueAsNumber;
                                                                break;
                                                        }
                                                        if (!state[prop][keyValue]) {
                                                            state[prop][keyValue] = state[prop][key];
                                                            delete state[prop][key];
                                                            modifyState(state);
                                                        }
                                                    }}
                                                        value={key} className="me-2 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                        placeholder="Key" required />
                                                    :
                                                    <input type={inputType} name={prop} id={prop} onChange={txt => {

                                                        switch (template.properties[prop].ValueType) {

                                                            case "String":
                                                            case "String?":
                                                            case "DateTime":
                                                            case "DateTime?":
                                                            case "DateTimeOffset":
                                                            case "DateTimeOffset?":
                                                                state[prop][key] = txt.target.value;
                                                                break;
                                                            case "Int32":
                                                            case "Int32?":
                                                            case "Int16":
                                                            case "Int16?":
                                                            case "Int64":
                                                            case "Int64?":
                                                            case "Single":
                                                            case "Single?":
                                                            case "Double":
                                                            case "Double?":
                                                                state[prop][key] = txt.target.valueAsNumber;
                                                                break;
                                                            case "Boolean":
                                                            case "Boolean?":
                                                                state[prop][key] = txt.target.checked;
                                                                break;
                                                        }
                                                        modifyState(state);
                                                    }} value={state[prop][key]} className="ms-2 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Placeholder" required />
                                                    <button type="button" className="flex justify-center items-center w-1/4 h-3/4 mt-1 text-center ms-3 rounded-lg bg-red-500 hover:bg-red-400"
                                                        onClick={() => {
                                                            if (confirm("Are you sure you want to delete this item?")) {
                                                                delete state[prop][key];
                                                                modifyState(state);
                                                            }
                                                        }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-4 block">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                        </svg>
                                                    </button>
                                                </div>);
                                        })
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
                {
                    hideSave ? "" :
                        <button type="button" onClick={() => onSave(state)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                }
            </div >
        </div >
    );
}

export default GenericEditor;